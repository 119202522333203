import { Flex } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { SEARCH_PARAMETERS_API_IDS } from '~/helpers/order/catalogue';

import './OrdersCatalogue.scss';
import OrdersTable from './OrdersTable/OrdersTable';
import SearchBar from './SearchBar/SearchBar';
import StickyTitleWithStatus from './StickyTitleWithStatus/StickyTitleWithStatus';

function retrieveParamsFromURL(params) {
  if (params === '') return {};

  const paramsObject = {};
  const URLGetParams = new URLSearchParams(params);
  Object.keys(SEARCH_PARAMETERS_API_IDS).forEach((key) => {
    const paramKey = SEARCH_PARAMETERS_API_IDS[key];
    const value = URLGetParams.get(paramKey);
    if (value) {
      paramsObject[paramKey] = value;
    }
  });
  return paramsObject;
}

export function paramsToURL(params, exclude = []) {
  const URLGetParams = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    if (params[key] === '' || key in exclude) return;
    URLGetParams.append(key, params[key]);
  });
  return '?' + URLGetParams.toString();
}

function OrdersCatalogue({ activeMerchantId, location, history, panelRef }) {
  const params = retrieveParamsFromURL(location.search);

  const changeParams = (newParams) => {
    history.push({
      pathname: location.pathname,
      search: paramsToURL(newParams),
    });
  };

  return (
    <>
      <StickyTitleWithStatus activeMerchantId={activeMerchantId} panelRef={panelRef} params={params} changeParams={changeParams} />
      <Flex className='orders-catalogue-wrapper' vertical gap={40}>
        <SearchBar activeMerchantId={activeMerchantId} params={params} changeParams={changeParams} />
        <OrdersTable activeMerchantId={activeMerchantId} params={params} changeParams={changeParams} />
      </Flex>
    </>
  );
}

export default connect((state) => {
  const { active_merchant } = state.session;
  return {
    activeMerchantId: active_merchant.id,
  };
})(OrdersCatalogue);
