import { useQuery } from '@tanstack/react-query';
import { Spin } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { MerchantExternalRouting } from '~/helpers/general';
import { api } from '~/services/api';

import './NewOrdersInfo.scss';

function NewOrdersInfo({ merchantId, activeMerchantSlug }) {
  const now = dayjs().startOf('day');
  const { isLoading, data } = useQuery({
    queryKey: ['newOrders', merchantId],
    queryFn: () =>
      api.get(`/v2/${merchantId}/orders_to_confirm/?count=1&from=${now.toISOString()}`).then((res) => {
        if (res.success) {
          return res.data.orders.count;
        }
        return null;
      }),
  });

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spin />
      </div>
    );
  }

  return (
    <div className='new-orders-block'>
      <a className={`${!data ? 'anchor-disabled' : ''}`} target='_blank' rel='noopener noreferrer' href={MerchantExternalRouting.orders(activeMerchantSlug)}>
        <div className='new-order-info'>
          {data ? (
            <>
              <p className='sub1'>Hourra ! vous avez : </p>
              <p className='sub1 new-order-count'>
                {data} nouvelle{data > 1 && 's'} commandes
              </p>
            </>
          ) : (
            <p className='sub1'>Pour l'instant, vous n'avez pas de nouvelles commandes à confirmer.</p>
          )}
        </div>
        <p className='caption1'>Le {now.format('DD.MM.YYYY')}</p>
      </a>
    </div>
  );
}

export default connect((state) => ({ merchantId: state.session.active_merchant.id, activeMerchantSlug: state.session.active_merchant.merchant_slug }))(
  NewOrdersInfo
);
