import { Button, Flex } from 'antd';
import React from 'react';
import DownloadFileButton from '~/components/shared/DownloadFileButton/DownloadFileButton';
import SearchInput from '~/components/shared/SearchInput/SearchInput';
import { SEARCH_PARAMETERS_API_IDS } from '~/helpers/order/catalogue';
import { useOrdersExport } from '~/hooks/useExports';

import FiltersPanel from './FiltersPanel/FiltersPanel';
import './SearchBar.scss';

export function filterParams(params) {
  const paramsKeysInTabs = [
    SEARCH_PARAMETERS_API_IDS.STATUS,
    SEARCH_PARAMETERS_API_IDS.INCIDENT_STATUS,
    SEARCH_PARAMETERS_API_IDS.PAGE,
    SEARCH_PARAMETERS_API_IDS.LIMIT,
  ];
  return Object.keys(params).filter((key) => !paramsKeysInTabs.includes(key));
}

export default function SearchBar({ activeMerchantId, params, changeParams }) {
  const orderNumber = params[SEARCH_PARAMETERS_API_IDS.ORDER_ID_NUMBER];

  const paramsEnabled = filterParams(params).length > 0;

  const search = (value) => {
    const newParams = { ...params };
    if (value === '') {
      delete newParams[SEARCH_PARAMETERS_API_IDS.ORDER_ID_NUMBER];
    } else {
      newParams[SEARCH_PARAMETERS_API_IDS.ORDER_ID_NUMBER] = value;
    }
    changeParams(newParams);
  };

  return (
    <>
      <Flex className='panel bordered' vertical gap={16}>
        <p className='body1'>Filtrer vos commandes</p>
        <Flex className='search-bar' gap={16}>
          <SearchInput placeholder='Rechercher par numéro de commande' defaultValue={orderNumber} search={search} />
          <Flex className='search-btn-group' gap={8}>
            <FiltersPanel params={params} changeParams={changeParams} />
            <DownloadFileButton
              useQuery={() => useOrdersExport(activeMerchantId)}
              textConfig={{
                errorTitle: "Erreur de l'export des commandes",
                tootltip: 'Exporter les commandes au format CSV',
              }}
            />
            {paramsEnabled && (
              <Button
                type='text'
                onClick={() => {
                  const newParams = { ...params };
                  Object.keys(params).forEach((key) => {
                    if (key !== SEARCH_PARAMETERS_API_IDS.STATUS) {
                      delete newParams[key];
                    }
                  });
                  changeParams(newParams);
                }}
              >
                Effacer les filtres
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
