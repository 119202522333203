import { Breadcrumb, Divider, Flex, Spin } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AlertBasicServerError from '~/components/shared/AlertBasicServerError/AlertBasicServerError';
import StickyTitle from '~/components/shared/StickyTitle/StickyTitle';
import { RoutingUrl } from '~/helpers/general';
import { useOrderDetails } from '~/hooks/useOrderDetails';

import OrderCustomerInfo from './OrderCustomerInfo/OrderCustomerInfo';
import './OrderDetails.scss';
import OrderDocuments from './OrderDocuments/OrderDocuments';
import OrderItems from './OrderItems/OrderItems';
import OrderMoreInfo from './OrderMoreInfo/OrderMoreInfo';
import OrderShipping from './OrderShipping/OrderShipping';
import OrderStatusActions from './OrderStatusActions/OrderStatusActions';
import OrderStatusHistory from './OrderStatusHistory/OrderStatusHistory';
import OrderStatusStepper from './OrderStatusStepper/OrderStatusStepper';

export const transitionApiKeys = {
  AUTHORIZED: 'authorize',
  CONFIRMED: 'confirm',
  PROCESSED: 'process',
  CANCELED: 'cancel',
};

/* eslint-disable */
function OrderDetails({ panelRef, match, location, activeMerchantId }) {
  const { state } = location;
  const { params } = match;
  const { order_id } = params;

  const { order, pending, error } = useOrderDetails(order_id, activeMerchantId);

  if (pending) {
    return (
      <Flex className='order-details-loading-wrapper' justify='center' align='center'>
        <Spin size='large' />
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex className='order-details-loading-wrapper' justify='center' align='center'>
        <AlertBasicServerError message={error?.message} />
      </Flex>
    );
  }

  console.log(order);

  return (
    <>
      <StickyTitle target={panelRef.current} className='order-details-sticky-title'>
        <Breadcrumb
          items={[
            {
              title: 'Gérer les commandes',
              href: RoutingUrl.orders() + state?.fromCatalogue,
            },
            {
              title: `Commande ${order.id_number}`,
            },
          ]}
          itemRender={(item, params, items, paths) => {
            const last = items.indexOf(item) === items.length - 1;
            return last ? <span>{item.title}</span> : <Link to={item.href}>{item.title}</Link>;
          }}
        />
        <Flex vertical gap={8}>
          <h2 className='h2'>Commande {order.id_number}</h2>
        </Flex>
      </StickyTitle>
      <Flex className='order-details-wrapper' vertical gap={24}>
        <OrderStatusStepper order={order} />
        <Flex className='order-details-content-wrapper' gap={24}>
          <Flex className='order-details-content-left-wrapper' vertical gap={24}>
            <OrderItems order={order} />
            <OrderShipping order={order} />
          </Flex>
          <Flex className='order-details-content-right-wrapper' vertical gap={24}>
            <OrderStatusActions order={order} activeMerchantId={activeMerchantId} />
            <Divider />
            <OrderCustomerInfo shippindAddress={order.shipping_address} billingAddress={order.billing_address} customerEmail={order.user.email} />
            <OrderMoreInfo idOrder={order.id} paymentType={order.payment_type} />
            <OrderDocuments order={order} activeMerchantId={activeMerchantId} />
            <OrderStatusHistory statusHistory={order.status_history} />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}

export default connect(({ session }) => ({ activeMerchantId: session.active_merchant.id }))(OrderDetails);
