/* eslint-disable */
import { useQueryClient } from '@tanstack/react-query';
import { App, Button, Flex } from 'antd';
import React, { useState } from 'react';
import { STATUS_API_IDS } from '~/helpers/order/catalogue';
import { api } from '~/services/api';

export default function OrderStatusActions({ order, activeMerchantId }) {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState('');
  const { notification } = App.useApp();

  const cancelOrder = () => {
    setLoading('cancel');
    api.post(`/${activeMerchantId}/orders/${order.id}/cancel/`).then((res) => {
      if (res.success) {
        queryClient.invalidateQueries({ queryKey: ['order'], refetchType: 'all' });
        queryClient.refetchQueries({ stale: true });
      } else {
        const errorMessage = typeof res.message?.error === 'string' ? res.message.error : res.message.error?.message;
        notification.open({
          className: 'notification-dialogue-error',
          message: "Une erreur est survenue lors de l'annulation de la commande",
          description: errorMessage,
        });
      }
      setLoading('');
    });
  };
  const confirmOrder = () => {
    setLoading('confirm');
    api.post(`/${activeMerchantId}/orders/${order.id}/confirm/`).then((res) => {
      if (res.success) {
        queryClient.invalidateQueries({ queryKey: ['order'], refetchType: 'all' });
        queryClient.refetchQueries({ stale: true });
      } else {
        const errorMessage = typeof res.message?.error === 'string' ? res.message.error : res.message.error?.message;
        notification.open({
          className: 'notification-dialogue-error',
          message: 'Une erreur est survenue lors de la confirmation de la commande',
          description: errorMessage,
        });
      }
      setLoading('');
    });
  };

  switch (order.status) {
    case STATUS_API_IDS.AUTHORIZED:
      return (
        <Flex gap={8}>
          <Button className='grow' loading={loading === 'cancel'} disabled={loading != ''} onClick={cancelOrder}>
            Annuler
          </Button>
          <Button className='grow' loading={loading === 'confirm'} disabled={loading != ''} type='primary' onClick={confirmOrder}>
            Confirmer
          </Button>
        </Flex>
      );

    case STATUS_API_IDS.CONFIRMED:
    case STATUS_API_IDS.PROCESSED:
      return null;
  }

  return null;
}
