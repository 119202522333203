export const SEARCH_PARAMETERS_API_IDS = {
  CREATION_DATE_MIN: 'date_created_min',
  CREATION_DATE_MAX: 'date_created_max',
  MODIFICATION_DATE_MIN: 'date_modified_min',
  MODIFICATION_DATE_MAX: 'date_modified_max',
  ORDER_ID_NUMBER: 'order_id_number',
  USER_EMAIL: 'useremail',
  USERNAME: 'username',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  ITEM_SKU_EXACT: 'item_sku',
  ITEM_SKU_LIKE: 'itme_sku_like',
  ITEM_NAME: 'item_name',
  STATUS: 'status',
  INCIDENT_STATUS: 'incident_status',
  LIMIT: 'limit',
  PAGE: 'page',
  SORT: 'tri',
};

export const SORT_API_IDS = {
  DEFAULT: 1,
  ORDER_ID_NUMBER: 2,
  SHIPPING_OPTIONS: 3,
};

export const STATUS_API_IDS = {
  AUTHORIZED: '60',
  CONFIRMED: '80',
  PROCESSED: '85',
};

export const INCIDENT_STATUS_API_IDS = {
  PENDING_MERCHANT: 'pending_merchant',
  PENDING_CUSTOMER: 'pending_customer',
  PROCESSING: 'processing',
  NO_INCIDENT: 'no_incident',
};

export const INCIDENT_STATUS_OPEN_API_ID = '1';

export const defaultParams = {
  [SEARCH_PARAMETERS_API_IDS.STATUS]: STATUS_API_IDS.AUTHORIZED,
};

export const colorStatusMapping = {
  Initial: 'processing',
  'Paiement Autorisé': 'warning',
  Confirmé: 'processing',
  Traité: 'success',
  Annulé: 'error',
  Finalisé: 'success',
  'Retour en cours': 'warning',

  // Old statuses
  Envoyé: 'grey',
  Expirée: 'error',
  Payé: 'grey',
  Reçu: 'processing',
  Retournée: 'processing',
  'Partiellement remboursée': 'success',
};
