import { useQuery } from '@tanstack/react-query';
import { Affix, Flex } from 'antd';
import React from 'react';
import { INCIDENT_STATUS_OPEN_API_ID, SEARCH_PARAMETERS_API_IDS, STATUS_API_IDS } from '~/helpers/order/catalogue';
import { api } from '~/services/api';

import TabsGroup from '../TabsGroup/TabsGroup';
import './StickyTitleWithStatus.scss';

export default function StickyTitleWithStatus({ activeMerchantId, panelRef, params, changeParams }) {
  const { isLoading, data } = useQuery({
    queryKey: ['ordersCount', activeMerchantId],
    queryFn: () =>
      api.get(`/${activeMerchantId}/orders/count/`).then((res) => {
        if (res.success) {
          return res.data;
        }
        if (typeof res.message?.error === 'string') {
          throw new Error(res.message.error);
        }
        throw new Error(res.message.error.message);
      }),
  });

  if (isLoading) {
    return null;
  }

  if (!data) {
    return null;
  }

  const { countIncidentOpen, countOrderAuthorized, countOrderConfirmed } = data;

  const { STATUS, INCIDENT_STATUS } = SEARCH_PARAMETERS_API_IDS;

  const tabItems = [
    {
      label: 'Toutes',
      active: !params[STATUS]?.length && !params[INCIDENT_STATUS]?.length,
      param: {
        key: STATUS,
        value: '',
      },
    },
    {
      label: 'À confirmer',
      active: params[STATUS] === STATUS_API_IDS.AUTHORIZED,
      param: {
        key: STATUS,
        value: STATUS_API_IDS.AUTHORIZED,
      },
      count: {
        value: countOrderAuthorized,
        color: 'success',
      },
    },
    {
      label: 'À traiter',
      active: params[STATUS] === STATUS_API_IDS.CONFIRMED,
      param: {
        key: STATUS,
        value: STATUS_API_IDS.CONFIRMED,
      },
      count: {
        value: countOrderConfirmed,
        color: 'warning',
      },
    },
    {
      label: 'Incidents ouverts',
      active: params[INCIDENT_STATUS] === INCIDENT_STATUS_OPEN_API_ID,
      param: {
        key: INCIDENT_STATUS,
        value: INCIDENT_STATUS_OPEN_API_ID,
      },
      count: {
        value: countIncidentOpen,
        color: 'error',
      },
    },
    {
      label: 'Traitées',
      active: params[STATUS] === STATUS_API_IDS.PROCESSED,
      param: {
        key: STATUS,
        value: STATUS_API_IDS.PROCESSED,
      },
    },
  ].map((item) => ({
    ...item,
    onClick: () => {
      const newParams = { ...params };
      delete newParams[STATUS];
      delete newParams[INCIDENT_STATUS];
      newParams[item.param.key] = item.param.value;
      changeParams(newParams);
    },
  }));

  return (
    <Affix target={() => panelRef.current} offsetTop={0}>
      <Flex vertical gap={8} className='orders-catalogue-sticky-title-wrapper'>
        <h2 className='h2'>Gérer les commandes</h2>
        <TabsGroup items={tabItems} />
      </Flex>
    </Affix>
  );
}
