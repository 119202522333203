/* eslint-disable */
import { Button, Divider, Flex } from 'antd';
import React, { Fragment } from 'react';
import ExpandableContent from '~/components/shared/ExpandableContent/ExpandableContent';

import { transitionApiKeys } from '../OrderDetails';

const optionLabelMap = {
  '1_nom': 'Nom',
  '2_rue': 'Rue',
  '3_cp': 'CP',
  '4_ville': 'Ville',
  '5_pays': 'Pays',
  _relay_id: 'Relay ID',
  _reseau: 'Réseau',
};

export default function OrderShipping({ order }) {
  const { shipping_options } = order;
  const showPackageTracking = order.status_history.some((transition) => transition.transition === transitionApiKeys.CONFIRMED);

  return (
    <>
      <Flex className='panel bordered' vertical gap={24}>
        <p className='sub2'>Détails de la livraison</p>
        {shipping_options.map((shippingOption, index) => (
          <Fragment key={`shipping-option-${index}`}>
            <Flex className='shipping-option' vertical gap={16}>
              <Flex justify='space-between' align='center' gap={8}>
                <p className='body1 text-grey-medium'>{shippingOption.name}</p>
                <p className='body1 text-grey-medium'>{shippingOption.shipping_vat_included} €</p>
              </Flex>
              {shippingOption.options && Object.keys(shippingOption.options).length > 0 && (
                <ExpandableContent title='Informations supplémentaires'>
                  <Flex className='custom-list' vertical gap={4}>
                    {Object.keys(shippingOption.options).map((key) => (
                      <p key={`options-data-${key}`} className='body1'>
                        {optionLabelMap[key] ? optionLabelMap[key] : key}: {shippingOption.options[key]}
                      </p>
                    ))}
                  </Flex>
                </ExpandableContent>
              )}

              <ExpandableContent title='Articles'>
                <Flex vertical gap={16}>
                  {shippingOption.items.map((itemId) => {
                    const { name, sku, quantity } = order.items.find((item) => item.id === itemId);
                    return (
                      <Flex key={`item-${itemId}`} vertical gap={4}>
                        <p className='caption1 text-primary'>{name}</p>
                        <p className='caption1 text-grey-medium'>Quantité : {quantity}</p>
                        <p className='caption1 text-grey-medium'>SKU : {sku}</p>
                      </Flex>
                    );
                  })}
                </Flex>
              </ExpandableContent>
            </Flex>
            {shipping_options.length - 1 !== index && <Divider />}
          </Fragment>
        ))}
        {showPackageTracking && (
          <>
            <Divider />
            <Flex gap={10} justify='space-between' align='center'>
              <p className='body2'>Suivi de colis</p>
              <Button>Ajouter un suivi de colis</Button>
            </Flex>
          </>
        )}
      </Flex>
    </>
  );
}
