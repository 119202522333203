import { useQueries } from '@tanstack/react-query';
import { api } from '~/services/api';

function treatApiError(res) {
  if (typeof res.message?.error === 'string') {
    throw new Error(res.message.error);
  }
  throw new Error(res.message.error.message);
}

export const useOrderDetails = (orderId, merchantId) => {
  const queries = [
    {
      queryKey: ['orderDetails', orderId, merchantId],
      queryFn: () =>
        api.get(`/${merchantId}/orders/${orderId}/`).then((res) => {
          if (res.success) {
            const shipping_options = res.data.shipping_options;
            return {
              ...res.data,
              id: orderId,
              items: res.data.items.map((item) => ({
                ...item,
                shipping: shipping_options.find((shipping) => shipping.items.includes(item.id)),
              })),
            };
          }
          treatApiError(res);
        }),
    },
    {
      queryKey: ['orderStatusHistory', merchantId, orderId],
      queryFn: () =>
        api.get(`/${merchantId}/orders/${orderId}/transition_log/`).then((res) => {
          if (res.success) {
            return res.data.objects;
          }
          treatApiError(res);
        }),
    },
    {
      queryKey: ['orderCommissions', merchantId, orderId],
      queryFn: () =>
        api.get(`/${merchantId}/orders/${orderId}/commissions/`).then((res) => {
          if (res.success) {
            return res.data.objects;
          }
          treatApiError(res);
        }),
    },
  ];

  return useQueries({
    queries: queries,
    combine: (results) => {
      const [orderDetails, orderStatusHistory, orderCommissions] = results;
      return {
        order: {
          ...orderDetails.data,
          status_history: orderStatusHistory.data,
          commissions: orderCommissions.data,
        },
        pending: results.some((result) => result.isPending),
        error: results.find((result) => result.isError)?.error,
      };
    },
  });
};
